
import { BugManagementClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({})
export default class BugCategoryModal extends Vue {

    @Prop({
        default: () => new OM.TextIdentifier()
    }) model: OM.TextIdentifier;
    localModel: OM.TextIdentifier = new OM.TextIdentifier();

    @Prop() callback: any;

    created() {
        this.localModel = this.model;
    }

    saveCategory() {
        BugManagementClient.editCategory(this.localModel)
        .then(x => {
            this.callback();
        })
    }

    get checkIfDisable() {
        return !this.model.text;
    }

}
