
import { Options, Vue } from 'vue-class-component';
import { BugManagementClient } from '@/services/Services';
import BugCategoryModal from '../modals/bugCategoryModal.vue';
import * as OM from '@/Model';

@Options({
    components: {
    }
})


export default class BugCategories extends Vue {

    categories: OM.TextIdentifier[] = [];
    
    created() {
        this.init();
    }

    init() {
        BugManagementClient.getAllCategories()
        .then(x => {
            this.categories = x;
        })
    }

    editCat(cat: OM.TextIdentifier) {
        if(cat == null)
            cat = new OM.TextIdentifier();

        this.$opModal.show(BugCategoryModal, {
            model: cat,
            callback: () => {
                this.init();
                this.$opModal.closeLast();
            }
        })
    }

    deleteCat(catId: string) {
        BugManagementClient.deleteCategory(catId)
        .then(x => {
            this.init();
        })
    }
}
